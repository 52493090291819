.aboutCard {
  width: 875px;
  transform: translate(50%, 0%);
  padding: 10px;
}

.profileImage {
  border-radius: 25px;
}

.descriptionText {
  font-size: 1.3rem;
  font-weight: 700;
  color: #253d4b;
}

@media (max-width: 500px) {
  .descriptionText {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.profileImageDiv {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

@media (max-width: 1500px) {
  .profileImageDiv {
    width: 80%;
  }
}

.skillsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 60%;
}

@media (max-width: 1500px) {
  .skillsDiv {
    width: 80%;
  }
}

.bakingLink {
  color: #487792;
}

.galleryImg {
  margin-bottom: 50px;
}

.sectionH2 {
  font-size: 4.5rem;
  color: white;
}

@media (max-width: 500px) {
  .sectionH2 {
    font-size: 2rem;
  }
}

.skillsButton {
  font-size: 25px;
  margin: 5px;
  color: #000000;
  background-color: #d3dce3;
  pointer-events: none;
}

.honorsCard {
  margin-bottom: 10px;
  background-color: #d3dce3;
}

.container {
  position: relative;
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #5b5b5b;
  border-radius: 25px;
}

.container:hover .overlay {
  opacity: 0.5;
}

.container:hover .text {
  opacity: 1;
}

.container:hover .text2 {
  opacity: 1;
}

.text {
  position: absolute;
  top: 5%;
  left: 10%;
  opacity: 0;
  text-align: left;
  transition: .5s ease;
}

.text2 {
  color: white;
  font-size: 40px;
  font-weight: 600;
  position: absolute;
  top: 20%;
  left: 35%;
  opacity: 0;
  text-align: left;
  transition: .5s ease;
}

.headerText {
  color: white;
  font-size: 50px;
  font-weight: 600;
}

.headerText2 {
  margin-top: 0;
  color: white;
  font-size: 20px;
  font-weight: 300;
}

#float {
  position: relative;
  -webkit-animation: floatBubble 1.5s;
  animation: floatBubble 1.5s;
}

#float2 {
  position: relative;
  -webkit-animation: floatBubble 2s;
  animation: floatBubble 2s;
}

@-webkit-keyframes floatBubble {
  0% {
    top:500px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
@keyframes floatBubble {
  0% {
    top:500px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}