.aboutCard {
  width: 875px;
  transform: translate(50%, 0%);
  padding: 10px;
}

.profileImage {
  border-radius: 25px;
  pointer-events: none;
}

.profileImageDiv {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

@media (max-width: 1500px) {
  .profileImageDiv {
    width: 80%;
  }
}

.skillsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 60%;
}

@media (max-width: 1500px) {
  .skillsDiv {
    width: 80%;
  }
}

.skillsButton {
  font-size: 25px;
  margin: 5px;
  color: #000000;
  background-color: #d3dce3;
  pointer-events: none;
}

.honorsCard {
  margin-bottom: 10px;
  background-color: #d3dce3;
}

.honorLink {
  font-size: 15px; 
  color: #253d4b;
}

.honorLink:hover {
  color: #477692;
}

.expCardHeader {
  color: #545454;
  margin-left: 25px;
  font-size: 18px;
  font-weight: 300;
}

.expCardDates {
  color: #545454;
  margin-left: 25px;
  font-size: 15px;
  font-weight: 300;
}

h1 {
  font-size: 75px;
  color: #507991;
}

h2 {
  color: #253d4b;
}

#float {
  position: relative;
  -webkit-animation: floatBubble 1.5s;
  animation: floatBubble 1.5s;
}

#float2 {
  position: relative;
  -webkit-animation: floatBubble 2s;
  animation: floatBubble 2s;
}

@-webkit-keyframes floatBubble {
  0% {
    top:500px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
@keyframes floatBubble {
  0% {
    top:500px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}